import { get } from '.';

export function GetGoals(options = {}) {
	return get<any>("/app/api/goals", options);
}

export function GetHistoricalGoals(options = {}) {
	return get<any>("/app/api/goals/historical", options);
}

export function GetUserGoalsReport(options = {}) {
    return get<any>("/app/api/goals/user_goals_report", options);
}

export function GetUserGoalsOverviewReport(options = {}) {
	return get<any>("/app/api/goals/user_goals_overview_report", options);
}
