import { get, post, put } from '.';

export function CreateCampaign(options = {}) {
	return post<any>("/app/api/campaigns", options);
}

export function CopyCampaign(options = {}) {
	return post<any>("/app/api/campaigns/copy", options);
}

export function GetCampaignAvailableAssignments(id, options = {}) {
	return get<any>("/app/api/campaigns/" + (id || 0).toString() + "/available_assignments", options);
}
export function GetCampaigns(options = {}) {
	return get<any>("/app/api/campaigns", options);
}

export function FindCampaign(vid: string, options = {}) {
    return get<any>(`/app/api/campaigns/${vid}`, options);
}

export function ApplyDispositionGroupToCampaign(vid: string, options = {}) {
	return put<any>(`/app/api/campaigns/"${vid}/disposition_groups`, options);
}

export function UpdateCampaign(vid: string, options = {}) {
    return put<any>(`/app/api/campaigns/${vid}`, options)
}

export function GetCampaignsForSuperAdmin(options = {}) {
	return get<any>("/app/api/campaigns/super_admin", options);
}

export function GetManualCommunicationDeploymentCampaigns(options = {}) {
	return get<any>("/app/api/get_manual_communication_deployment_campaigns", options);
}

export function GetCampaignsForAccessGroups(options = {}) {
	return get<any>("/app/api/campaigns/access_groups", options);
}

export function GetCatalogCampaigns(options = {}) {
	return get<any>("/app/api/campaigns/catalog", options);
}

export function ProvisionCatalogCampaigns(options = {}) {
	return post<any>("/app/api/campaigns/catalog", options);
}

export function GetAvailableIncomingPhoneNumbers(id: string, options = {}) {
	return get<any>(`/app/api/campaigns/${id}/available_incoming_phone_numbers`, options)
}

//Get Campaign communication queue users
export function GetCampaignCommunicationQueueUsers(id: string, options = {}) {
    return get<any>(`/app/api/campaigns/${id}/communication_queue_users`, options)
}

export function GetCommunicationCenterCampaign(id: string) {
	return get<any>(`/app/api/campaigns/${id}/communication_center_campaign`)
}


export function GetCampaignCustomersExpirationSummary(vid, options = {}){
	return get<any>(`/app/api/campaigns/${vid}/expiration_summary`, options)
}

export function GetOtherOrganizationCampaigns(id: string) {
	return get<any>(`/app/api/campaigns/${id}/other_organization_campaigns`)
}

export function GetCampaignsDiff(options = {}) {
	return get<any>(`/app/api/campaigns/diff`, options)
}
