<template lang="pug">
    v-tooltip(top max-width="500")
        template(v-slot:activator='{ on, attrs }')
            span(v-bind="attrs" v-on="on" role="text" :style="{ color: textColor, fontWeight: boldText ? 'bold' : 'normal'}")
                template(v-if="validRouterLinkUrl")
                    router-link.no-text-underline(:to="routerLinkUrl" :target="targetBlank ? '_blank' : '_self'")
                        template(v-if="isHtml")
                            | {{ (text || '').trim().replace(/(<([^>]+)>)/ig, '') | str_limit(stringLimit) }}
                        template(v-else)
                            | {{ (text || '').trim() | str_limit(stringLimit) }}
                template(v-else)
                    template(v-if="isHtml")
                        | {{ (text || '').trim().replace(/(<([^>]+)>)/ig, '') | str_limit(stringLimit) }}
                    template(v-else)
                        | {{ (text || '').trim() | str_limit(stringLimit) }}
        span(v-if="isHtml")
            v-container(v-html="text")
        span(v-else)
            | {{ displayTooltip }}
</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        data() {
            return {
                stringLimit: 25,
            }
        },
        methods: {
            setStringLimit() {
                if (this.charLimit > 0) {
                    return this.stringLimit = this.charLimit;
                }
            },
        },
        computed: {
            displayTooltip() {
                if (this.altTooltip?.length > 0) {
                    return this.altTooltip;
                }

                return this.text;
            },
            validRouterLinkUrl() {
                return this.routerLinkUrl?.length > 0;
            },
        },
        props: {
            altTooltip: String,
            charLimit: Number,
            boldText: Boolean, 
            routerLinkUrl: String,
            targetBlank: Boolean,
            textColor: String, 
            text: String,
            isHtml: Boolean, 
        },
        created() {
            this.setStringLimit();
        },
    });
</script>

<style lang="scss" scoped>
</style>
