<template lang="pug">
    v-dialog(
        v-model='dialog'
        max-width="600"
        transition='dialog-bottom-transition'
    )
        template(v-slot:activator='{ on, attrs }')
            v-btn.white--text.mr-4(
                color="info"
                v-bind='attrs'
                v-on='on'
                dark
                small
            )
                v-icon mdi-phone-return
                | Missed Call Details

        v-card
            v-toolbar
                v-btn(icon @click='closeDialog();')
                    v-icon mdi-close
                v-toolbar-title Missed Call Details

            template(v-if="isConfirmingArchive")
                v-card-text
                    v-container
                        span.text-body-1 Please confirm that there is no action required for this missed call and that you would like to archive it.

                v-card-actions.text-right
                    v-spacer
                        v-btn.ma-2(color="#0f5b9a" outlined ripple @click='isConfirmingArchive = false;')
                            v-icon mdi-window-close
                            | Cancel

                        v-btn.ma-2(
                            color="red"
                            outlined
                            ripple
                            @click='nothingToDo();'
                            :disabled="isLoading"
                        )
                            v-icon mdi-delete
                            | Confirm Archive

            template(v-else)
                v-card-text
                    template(v-if="validVoiceMailRecording")
                        v-list(two-line)
                            template
                                v-list-item
                                    v-list-item-content
                                        v-list-item-title {{ voiceMailRecording.organization_name }}
                                        v-list-item-subtitle.text--primary(v-text='voiceMailRecording.campaign_name')

                            template
                                v-list-item
                                    v-list-item-icon
                                        v-icon(color='primary')
                                            | mdi-clock-outline
                                    v-list-item-content
                                        v-list-item-title {{ voiceMailRecording.friendly_created_at }}
                                        v-list-item-subtitle.text--primary {{ voiceMailRecording.purpose | titleize }}

                            template
                                v-divider(inset)
                                v-list-item
                                    v-list-item-icon
                                        v-icon(color='primary')
                                            | mdi-phone
                                    v-list-item-content
                                        v-list-item-title {{ voiceMailRecording.communication_queue_name }}
                                        v-list-item-subtitle {{ voiceMailRecording.language_name }}

                            template
                                v-divider(inset)
                                v-list-item
                                    v-list-item-icon
                                        v-icon(color='primary')
                                            | mdi-magnify
                                    v-list-item-content
                                        v-list-item-title
                                            template(v-if="voiceMailRecording.customer_match_code === 1") Perfect
                                            template(v-else-if="voiceMailRecording.customer_match_code === 2") Multiple potential customers found
                                            template(v-else) No customer matches
                                        v-list-item-subtitle Match Status

                            template
                                v-divider(inset)
                                v-list-item
                                    v-list-item-icon
                                        v-icon(color='primary')
                                            | mdi-account
                                    v-list-item-content
                                        v-list-item-title {{ voiceMailRecording.from_number | tel }}
                                        v-list-item-subtitle Caller ID
                                v-list-item(v-if="validCustomerFullName")
                                    v-list-item-icon
                                    v-list-item-content
                                        v-list-item-title {{ voiceMailRecording.customer_full_name }}
                                        v-list-item-subtitle Name

                            template
                                v-divider(inset)
                                v-list-item(v-if="validRecording")
                                    v-list-item-content
                                        VolieAudio(
                                            :file="recordingUrl"
                                            color="success"
                                            :downloadable="true"
                                        )
                                v-list-item(v-else)
                                    v-list-item-content
                                        v-list-item-title No recording available for this missed call
                            template(v-if="hasAutoAssistantData")
                                v-divider(inset)
                                v-list-item(v-if="isAutoAssistantDataLoading")
                                v-list-item(v-else-if="validAutoAssistantData")
                                    v-list-item-content 
                                        v-list-item-title Stella AI Missed Call Data
                                        StellaAiData(
                                            :auto-assist-data="autoAssistantData"
                                            :valid-auto-assist-data="validAutoAssistantData"
                                        )
                                v-list-item(v-else) 
                                    v-list-item-content
                                        v-list-item-title Unable to load Auto Assistant data

                v-card-actions.text-right
                    v-spacer
                        v-btn.ma-2(color="#0f5b9a" outlined ripple @click='closeDialog();')
                            v-icon mdi-window-close
                            | Close

                        v-btn.ma-2(
                            color="red"
                            outlined
                            ripple
                            @click='isConfirmingArchive = true;'
                            :disabled="isLoading"
                        )
                            v-icon mdi-delete
                            | Archive

                        v-btn.ma-2(
                            color="green"
                            outlined
                            ripple
                            @click='returnCall();'
                            :disabled="returnCallDisabled"
                        )
                            v-icon mdi-phone
                            | Return Call

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import VolieAudio from '@/components/shared/audio_player.vue';
    import StellaAiData from '@/components/ai_assistants/stella_ai_data.vue';
    
    // Data
    import { UpdateVoiceMailRecording, FindAutoAssistantRequest } from "@/data";

    export default Vue.extend({
        data() {
            return {
                autoAssistantData: null,
                dialog: false,
                isConfirmingArchive: false,
                isLoading: false,
                isAutoAssistantDataLoading: false,
            }
        },
        methods: {
            closeDialog() {
                this.dialog = false;
                this.isConfirmingArchive = false;
            },
            findAutoAssistantRequest() {

                // if we don't have a valid id, return
                if (this.voiceMailRecording?.auto_assistant_request_id <= 0) {
                    return;
                }

                this.isAutoAssistantDataLoading = true;

                FindAutoAssistantRequest(this.voiceMailRecording.auto_assistant_request_id).then((response) => {
                    if (response?.Error === null && response.Data?.AutoAssistantRequest.id > 0) {
                        this.autoAssistantData = response.Data.AutoAssistantRequest;
                    }

                    this.isAutoAssistantDataLoading = false;
                })
            },
            nothingToDo() {

                // Verify
                if (!this.validVoiceMailRecording) {
                    return;
                }

                const options = {
                    nothing_to_do: true,
                }

                this.isLoading = true;
                UpdateVoiceMailRecording(this.voiceMailRecording.voice_mail_recording_id, options).then((response) => {
                    if (response?.Data?.voice_mail_recording?.id > 0) {

                        // Ensure
                        if (this.validSnackbar) {
                            this.showSnackbar("green", "Successfully archived missed call");
                        }

                        // Close
                        this.closeDialog();

                        // If present, call function
                        if (this.endVoiceMailRecording !== undefined && this.endVoiceMailRecording !== null) {
                            this.endVoiceMailRecording();
                        }
                    } else {
                        // Ensure
                        if (this.validSnackbar) {
                            this.showSnackbar("error", "Something went wrong archiving missed call");
                        }
                        this.isLoading = false;
                    }
                });
            },
            returnCall() {

                // Verify
                if (this.returnCallDisabled) {
                    return;
                }

                this.startForceStartNextDial();
                this.closeDialog();
            },
        },
        computed: {
            hasAutoAssistantData() {
                return this.voiceMailRecording?.auto_assistant_request_id > 0;
            },
            recordingUrl() {
                return this.voiceMailRecording?.recording_url;
            },
            returnCallDisabled() {
                return this.validCall || !this.validStartForceStartNextDial;
            },
            validAutoAssistantData() {
                return this.autoAssistantData?.auto_assistant_event_id > 0 && this.autoAssistantData?.event_body?.length > 0;
            },
            validCustomerFullName() {
                return this.voiceMailRecording?.customer_id > 0 && this.voiceMailRecording?.customer_full_name?.length > 0;
            },
            validCall() {
                return this.callId > 0;
            },
            validRecording() {
                return this.recordingUrl?.length > 0;
            },
            validSnackbar() {
                return this.showSnackbar !== undefined && this.showSnackbar !== null;
            },
            validStartForceStartNextDial() {
                return this.startForceStartNextDial !== undefined && this.startForceStartNextDial !== null;
            },
            validVoiceMailRecording() {
                return this.voiceMailRecording?.voice_mail_recording_id > 0;
            },
        },
        components: {
            VolieAudio,
            StellaAiData,
        },
        props: {
            callId: Number,
            endVoiceMailRecording: Function,
            showSnackbar: Function,
            startForceStartNextDial: Function,
            voiceMailRecording: Object,
        },
        watch: {
        },
        created() {
            if (this.hasAutoAssistantData) {
                this.findAutoAssistantRequest();
            }
        },
        destroyed() {
        },
    });
</script>

<style lang="scss" scoped>
</style>
