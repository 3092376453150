<template lang="pug">

    v-list-item(style="min-height: 60px;")
        v-list-item-icon(v-if="icon || indent")
            slot(name="icon")
                v-icon(v-if="icon" :color="iconColor ||'primary'")
                    | {{ icon }}

        v-list-item-content.py-1
            v-list-item-title
                slot(name="title")
                    template(v-if="titleText")
                        template(v-if="numberFilter")
                            | {{ (titleText || '') | number }}
                        template(v-else-if="centsToDollarFilter")
                            | {{ (titleText || '') | cents_to_dollar }}
                        template(v-else-if="dollarFilter")
                            | {{ (titleText || '') | dollar }}
                        template(v-else-if="multiLineTitle")
                            .white-space-normal
                                template(v-if="!showAllTitleText")
                                    | {{ titleText | str_limit(titleStrLimit) }}

                                    template(v-if="(titleTextLength > titleStrLimit)")
                                        br
                                        v-btn(text small color="primary" @click="showAllTitleText = true;")
                                            | View Full Text

                                template(v-else)
                                    | {{ titleText }}

                        CopyableText(v-else :text="(titleText || '')")
                    template(v-else-if="defaultTitleText")
                        | {{ defaultTitleText }}
            v-list-item-subtitle(v-if="subtitleText")
                slot(name="subtitle")
                    | {{ subtitleText }}

        slot(name="action")

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import CopyableText from '@/components/shared/copyable_text.vue';

    export default Vue.extend({
        data() {
            return {
                showAllTitleText: false,
            }
        },
        computed: {
            defaultTitleText() {
                return this.defaultTitle || null;
            },
            subtitleText() {
                return this.subtitle || null;
            },
            titleStrLimit() {
                return this.multiLineTitleStrLimit || 150;
            },
            titleText() {
                if ((this.numberFilter || this.dollarFilter || this.centsToDollarFilter) && this.title) {
                    if (this.title > 0) {
                        return this.title;
                    } else {
                        return null;
                    }
                }
                return this.title || null;
            },
            titleTextLength() {
                return (this.titleText || '').toString().length;
            },
        },
        props: {
            centsToDollarFilter: Boolean, // formats value in cents to dollar i.e. 500 cents -> $5.00
            defaultTitle: String,
            dollarFilter: Boolean,
            icon: String,
            iconColor: String, 
            indent: Boolean,
            multiLineTitle: Boolean,
            multiLineTitleStrLimit: Number, // Add multiline string length limit. Will have show more ellipsis button to view full note
            numberFilter: Boolean,
            subtitle: String,
            title: [Number, String],
        },
        components: {
            CopyableText,
        },
    });
</script>

<style lang="scss" scoped>
</style>
