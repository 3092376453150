<template lang="pug">
    div
        v-list-item(style="flex: 1; display: flex; flex-direction: row; align-items: center; padding: 0;")
            v-list-item-content(style="flex: 0 0 auto; display: flex; flex-direction: row;")
                v-list-item-title(style="font-weight: bold; font-size: .9rem;")
                    template(v-if="goal.recurring_type === 'daily'") Today
                    template(v-else-if="goal.recurring_type === 'weekly'") Week
                    template(v-else-if="goal.recurring_type === 'monthly'") Month
                    template(v-else-if="goal.recurring_type === 'quarterly'") Quarter
                    template(v-else) Year

                div(style="display: flex; gap: 10px; font-size: 1.1em; margin-top: 4px; color: black;")
                    div
                        | {{ goal.current_value | number }}/{{ goal.target_value | number }}

            v-progress-circular(
                class="position-relative"
                :color="progressColorMenu()"
                rotate="0"
                :size="80"
                :style="{ marginRight: index === arrayLength - 1 ? '10px' : '0' }"
                :width="10"
                :value="goalProgressPercentage"
            )
                span(v-if="goal.current_value" style="color: black;") {{ Math.floor(goalProgressPercentage) }}%
                span(v-else style="color: black;") 0%

</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        data() {
            return {
            }
        },
        methods: {
            progressColorMenu() {
                if (this.goalProgressPercentage > 0 && this.goalProgressPercentage < 25) {
                    return "error";
                } else if (this.goalProgressPercentage >= 25 && this.goalProgressPercentage < 75) {
                    return "warning lighten-2";
                } else if (this.goalProgressPercentage >= 75 && this.goalProgressPercentage < 100) {
                    return "primary";
                } else if (this.goalProgressPercentage >= 100) {
                    return "success";
                } else {
                    return "grey";
                }
            },
        },
        computed: {
            goalProgressPercentage() {
                if (this.goal.current_value > 0) {
                    return (this.goal.current_value / this.goal.target_value) * 100.0;
                } else {
                    return 0;
                }
            },
        },
        props: {
            arrayLength: Number,
            goal: Object,
            index: Number,
        },
    });
</script>

<style lang="scss" scoped>

#app {
    .position-relative {
        position: relative;
    }
}
</style>
