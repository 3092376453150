<template lang="pug">
    div.fluid.ma-0.pa-0.fill-height()
        .pa-md-4.brown.lighten-5(style="height: 100vh;" ref="cpContentRow" v-if="validCustomerID")
            v-snackbar(
                v-model='snackbar.show'
                :color='snackbar.color'
                :timeout="snackbar.timeout"
                top
            )
                | {{ snackbar.text }}
                v-btn(dark text @click='snackbar.show = false')
                    | Close

            CustomerProfile.pa-2(
                v-bind:style="{ height: customerProfileHeight + 'px' }"
                v-if="validCurrentCampaignCustomer"
                :communication-manager="communicationManager"
                :current-campaign-customer="currentCampaignCustomer"
                :customer-profile-height="customerProfileHeight"
                :handle-update-current-campaign-customer="handleUpdateCurrentCampaignCustomer"
                :hide-create-update-destroy-buttons="hideCreateUpdateDestroyButtons"
                :in-communication-center="false"
                :set-communication-manager="setCommunicationManager"
                :show-snackbar="showSnackbar"
                :source="source"
                :exit="exit"
            )

            v-alert(v-if="!validCurrentCampaignCustomer && !isLoading" outlined type='info' dense border='left' style="margin-top: 0px;")
                | Unable to load customer.

            div.text-center(v-if="isLoading")
                v-progress-circular(
                    :size='100'
                    :width='10'
                    color='#0f5b9a'
                    indeterminate
                    style="margin-top: 75px; margin-bottom: 75px;"
                )

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import CustomerProfile from '@/components/communication_center/shared/customer_profile.vue';

    // Data
    import { BuildAssignedCampaignCustomerForCustomer } from "@/data";

    // Helpers
    import { UpdateAssignedCustomerFromCustomerCommunicationManager } from "@/helpers/customer";

    export default Vue.extend({
        data() {
            return {
                communicationManager: null,
                currentCampaignCustomer: null,
                customerProfileHeight: null,
                isLoading: false,
                snackbar: {
                    show: false,
                    timeout: 2500,
                },
            }
        },
        methods: {
            buildAssignedCampaignCustomerForCustomer() {

                if (!this.validCustomerID) {
                    return;
                }

                this.isLoading = true;
                BuildAssignedCampaignCustomerForCustomer(this.customerId, {}).then((response) => {
                    if (response?.Data?.assigned_campaign_customer?.customer_id > 0) {
                        this.currentCampaignCustomer = response?.Data?.assigned_campaign_customer;
                    } else {
                        this.showSnackbar("error", "Unable to load resource customer.");
                    }
                    this.isLoading = false;
                });
            },
            handleUpdateCurrentCampaignCustomer(options) {

                // Verify same customer
                if (!(options?.customer_id > 0 && options.customer_id === this.currentCampaignCustomer?.customer?.customer_id)) {
                    return;
                }

                // Set customer params
                this.currentCampaignCustomer.customer = UpdateAssignedCustomerFromCustomerCommunicationManager(options, this.currentCampaignCustomer.customer);
                return;
            },
            handleWindowResize() {

                // Declare constants
                const paddingPx = 32;
                const topHeader = 64;
                const breadcrumbHeader = this.includeBreadcrumbsInHeight ? 57 : 0;

                // Calcuate
                this.customerProfileHeight = this.$refs.cpContentRow?.clientHeight - topHeader - paddingPx - breadcrumbHeader;
            },
            setCommunicationManager(communicationManager) {
                this.communicationManager = communicationManager;
            },
            setHtmlOverflow(value) {
                const htmlTag = document.getElementsByTagName('html')[0];
                if (!!htmlTag) {

                    // remove tags
                    htmlTag.classList.remove('overflow-y-hidden');
                    htmlTag.classList.remove('overflow-y-scroll');

                    // If hidden, need to scroll back to top
                    if (value === "hidden") {
                        window.scrollTo(0, 0);
                        htmlTag.classList.add('overflow-y-hidden');

                    } else if(value === "scroll"){
                        htmlTag.classList.add('overflow-y-scroll');
                    }
                }
                return;
            },
            showSnackbar(color, text) {
                if (color?.length > 0 && text?.length > 0) {
                    this.snackbar.color = color;
                    this.snackbar.text = text;
                    this.snackbar.show = true;
                }
            },
        },
        computed: {
            validCustomerID() {
                return this.customerId > 0;
            },
            validCurrentCampaignCustomer() {
                return this.currentCampaignCustomer?.customer_id > 0
            },
        },
        components: {
            CustomerProfile,
        },
        props: {
            customerId: Number,
            hideCreateUpdateDestroyButtons: Boolean,
            includeBreadcrumbsInHeight: Boolean,
            source: String,
            exit: Function,
        },
        watch: {
            "customerId"() {
                this.currentCampaignCustomer = null;

                Vue.nextTick(() => {
                    this.buildAssignedCampaignCustomerForCustomer();
                });
            },
        },
        destroyed() {
            window.removeEventListener('resize', this.handleWindowResize);
            window.removeEventListener('unload', this.handleWindowClosed);
        },
        created() {
            this.buildAssignedCampaignCustomerForCustomer();
        },
        mounted() {
            this.setHtmlOverflow("hidden");
            window.addEventListener('resize', this.handleWindowResize);
            window.addEventListener('unload', this.handleWindowClosed);
            this.handleWindowResize();
        },
    });
</script>

<style lang="scss" scoped>
</style>
