<template lang="pug">
    div
        v-container(v-if="goalsEnabled")
            div(v-if="validGoals" style="display: flex; flex-direction: row; justify-content: flex-end; align-items: center; gap: 12px;")
                GoalMenu(v-for="goal in displayGoalsByPriority" :key="goal.id" :goal="goal" :goals="goals")

            template(v-else style="display: flex; flex-direction: row; justify-content: flex-end; align-items: center; gap: 12px;")
                v-menu(
                    offset-y
                    class="position-relative"
                    :close-on-content-click="false"
                )
                    template(v-slot:activator="{ on, attrs }")
                        v-btn(
                            v-bind="attrs"
                            v-on="on"
                            class="goals-btn"
                        )
                            v-progress-circular(
                                :size="42"
                                :width="4"
                                color="grey"
                                rotate="0"
                                class="position-relative"
                            )
                                v-icon(
                                    class="position-absolute"
                                    color="grey"
                                    size="16"
                                ) mdi-trophy

                    v-list
                        v-list-item
                            v-list-item-content
                                v-list-item-title(
                                    style="font-weight: bold;"
                                )
                                    v-alert(
                                        icon="mdi-trophy"
                                        prominent
                                        text
                                        type="info"
                                        class="text-center"
                                        style="margin-bottom: 0px;"
                                    )
                                        span(v-if="currentUserIsAgent") Oh no! It looks like you don't have any goals. Speak with your manager today about setting them up.
                                        span(v-else) Oh no! It looks like you don't have any goals.
                                            v-btn(
                                                style="margin-left: 10px;"
                                                rounded
                                                color="primary"
                                                dark
                                                :to="{ name: 'goal_templates_index' }"
                                            )
                                                | Launch goals now

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState from '@/store';

    // Components
    import GoalMenu from "@/components/goals/user_goals/goal_menu.vue";

    // Data
    import { GetGoals } from "@/data";

    export default Vue.extend({
        data() {
            return {
                dailyGoals: [],
                goals: [],
                goalsEnabled: false,
            }
        },
        methods: {
            filterDailyGoals() {
                this.dailyGoals = [];

                for (const goal of this.goals) {
                    if (goal.recurring_type === 'daily') {
                        this.dailyGoals.push(goal);
                    }
                }
            },
            getGoals() {
                const options = {
                    ownable_id: this.currentUser?.user_id || 0,
                    ownable_type: "user",
                }

                GetGoals(options).then((response) => {
                    if (response?.Data) {
                        this.goalsEnabled = response.Data.goals_enabled;
                        if (response?.Data?.goals?.length > 0) {
                            this.goals = response.Data.goals;
                            this.filterDailyGoals();
                        }
                    }
                });
            },
            init() {
                this.getGoals();
            },
        },
        computed: {
            currentUser() {
                return VolieState.currentUser();
            },
            currentUserIsAgent() {
                return this.currentUser?.activated_role_id == 3;
            },
            displayGoalsByPriority() {
                const order = ['daily', 'weekly', 'monthly', 'quarterly', 'yearly'];
                const goalTypes = [...new Set(this.goals.map(goal => goal.goal_type))];
                const result = [];

                goalTypes.forEach(goalType => {
                    for (const priority of order) {
                        const goal = this.goals.find(g => g.goal_type === goalType && g.recurring_type === priority);
                        if (goal) {
                            result.push(goal);
                            break;
                        }
                    }
                });

                return result;
            },
            validGoals() {
                return this.goals?.length > 0;
            }
        },
        components: {
            GoalMenu,
        },
        props: {
        },
        created() {
            this.init();
        },
    });
</script>

<style lang="scss" scoped>
#app {
    .goals-btn {
        min-width: 50px;
        max-width: 50px;
        min-height: 50px;
        max-height: 50px;
        width: 50px !important;
        height: 50px !important;
        border-radius: 50%;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .position-absolute {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .position-relative {
        position: relative;
    }
}
</style>
