<template lang="pug">
    //- v-card.mx-lg-auto.mt-md-4(outlined)
    v-card-text
        v-container
            v-row
                v-col(cols='12')
                    v-text-field(
                        v-model="obj.title"
                        label='Title *'
                        type='text'
                    )

                v-col(cols='12')
                    | Description
                    tiptap-vuetify(
                        v-model='obj.description'
                        :extensions='extensions'
                        :toolbar-attributes="{ color: '#0f5b9a', dark: true }"
                        style=""
                    )

                v-col(cols='12' v-if="currentUserIsSuperAdmin && !validTicket")
                    v-autocomplete(
                        v-model='obj.account_id'
                        :items='accounts'
                        label='Account'
                        item-text="name"
                        item-value="account_id"
                        @change="onSelectAccount()"
                    )

                v-col(cols='12' v-if="!disableAssignables")
                    v-autocomplete(
                        v-model='obj.assignable_id'
                        label='Assignable'
                        type='text'
                        :items="assignables"
                        item-text="assignable_display_name"
                        item-value="assignable_id"
                        clearable
                    )
                        template(v-slot:selection="data")
                            | {{ data.item.assignable_display_name }}
                        template(v-slot:item='data')
                            v-list-item-content
                                v-list-item-title(style="margin-left: 16px;") {{ data.item.assignable_display_name }}

                v-col(cols='6')
                    v-autocomplete(
                        v-model='obj.ticket_type'
                        label='Type *'
                        :items='types'
                        item-text='key'
                        item-value='value'
                    )

                v-col(cols='6')
                    v-autocomplete(
                        label='Priority *'
                        v-model='obj.priority'
                        :items='priorities'
                        item-text='key'
                        item-value='value'
                    )

                template(v-if="validTicket")
                    v-col(cols='6' v-if="currentUserIsSuperAdmin")
                        v-autocomplete(
                            v-model='obj.current_status'
                            label='Status *'
                            :items='statuses'
                            item-text='key'
                            item-value='value'
                        )

                    v-col(cols='6' v-if="obj.current_status == 'done'")
                        v-autocomplete(
                            v-model='obj.completion_code'
                            label='Completion Code *'
                            :items='completionCodes'
                            item-text='key'
                            item-value='value'
                        )

                    v-col(cols='6' v-if="obj.completion_code == 'completed'")
                        v-text-field(
                            v-model='obj.branch_name'
                            label='Branch Name'
                            type='text'
                        )

                    v-col(cols='6' v-if="obj.completion_code == 'completed'")
                        v-text-field(
                            v-model='obj.commit_number'
                            label='Commit Number'
                            type='text'
                        )

                    template(v-if="currentUserIsSuperAdmin")
                        v-col(cols='6')
                            v-text-field(
                                v-model='obj.internal_development_estimate_hours'
                                label='Development Estimate Hours'
                                type='number'
                                @keydown="checkDigit"
                                @input="sanatizeEstimateHours()"
                            )

                        v-col(cols='6')
                            v-text-field(
                                v-model='obj.internal_development_actual_hours'
                                label='Development Actual Hours'
                                type='number'
                                @keydown="checkDigit"
                                @input="sanatizeActualHours()"
                            )

                        v-col(cols='6')
                            v-text-field(
                                v-model='obj.internal_development_priority'
                                label='Development Priority'
                                type='number'
                                @keydown="checkDigit"
                                :rules="[v => v === '' || v === null || (v >= 1 && v <= 10) || 'Value must be between 1 and 10']"
                            )

                        v-col(cols='6')
                            v-autocomplete(
                                v-model='obj.internal_development_size'
                                label='Development Size'
                                :items='sizes'
                                item-text='key'
                                item-value='value'
                            )

                        v-col(cols='6')
                            v-autocomplete(
                                v-model='obj.internal_development_stage'
                                label='Development Stage'
                                :items='shownStages'
                                item-text='key'
                                item-value='value'
                            )

                    v-col(cols='12')
                        | Add Comment
                        tiptap-vuetify(
                            v-model='obj.comments'
                            :extensions='extensions'
                            :toolbar-attributes="{ color: '#0f5b9a', dark: true }"
                        )

                    v-col(cols='12')
                        Asset(
                            :account-id="currentUser.activated_account_id"
                            :show-snackbar="showSnackbar"
                            :ticket-id="ticket.ticket_id"
                            :updated-assets="onUpdatedAssets"
                        )

            v-col(cols='12' style="padding-left: 0px; padding-right: 0px;")
                .text-right
                    v-btn.ma-2(v-if="!validTicket" outlined ripple :to="{ name: 'ticket_index' }")
                        v-icon mdi-window-close
                        | Cancel

                    v-btn.ma-2.white--text(v-if="!validTicket" :loading="isSaving" :disabled="!eligibleToSave" ripple color="green" @click="createTicket()")
                        v-icon mdi-content-save
                        | Create

                    v-btn.ma-2.white--text(v-if="validTicket" :loading="isSaving" :disabled="!eligibleToSave" ripple color="green" @click="updateTicket()")
                        v-icon mdi-content-save
                        | Update

                    ReopenTicketDialog(
                        v-if="validTicket && ticket.current_status === 'done'"
                        :reload-ticket="reloadTicket"
                        :show-snackbar="showSnackbar"
                        :ticket="ticket"
                    )

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState from '@/store';

    // Data
    import {
        CreateTicket,
        GetAccounts,
        GetAssignables,
        UpdateTicket,
    } from "@/data";

    // Components
    import Asset from '@/components/tickets/partials/asset.vue';
    import ReopenTicketDialog from '@/components/tickets/reopen_ticket_dialog.vue';
    import {
        TiptapVuetify,
        Heading,
        Bold,
        Italic,
        Strike,
        Underline,
        // Code,
        Paragraph,
        BulletList,
        OrderedList,
        ListItem,
        Link,
        Blockquote,
        HardBreak,
        // HorizontalRule,
        // History,
    } from 'tiptap-vuetify';

    export default Vue.extend({
        data() {
            return {
                accounts: [],
                assignables: [],
                checkDigit: (event: KeyboardEvent) => {
                    if (event.key.length === 1 && isNaN(Number(event.key))) {
                        event.preventDefault();
                    }},
                completionCodes: [
                    { key: "Completed", value: "completed" },
                    { key: "Duplicate", value: "duplicate" },
                    { key: "Not Able To Reproduce", value: "not_able_to_reproduce" },
                    { key: "Not Going To Do", value: "not_going_to_do" },
                ],
                extensions: [
                    Blockquote,
                    Link,
                    Underline,
                    Strike,
                    Italic,
                    ListItem,
                    BulletList,
                    OrderedList,
                    [Heading, {
                        options: {
                            levels: [1, 2, 3]
                        }
                    }],
                    Bold,
                    Paragraph,
                    HardBreak
                ],
                isSaving: false,
                obj: {},
                priorities: [
                    { key: "Critical", value: "critical" },
                    { key: "High", value: "high" },
                    { key: "Low", value: "low" },
                ],
                savedStage: "",
                shownStages: [],
                sizes: [
                    { key: "Small", value: "small" },
                    { key: "Medium", value: "medium" },
                    { key: "Large", value: "large" },
                    { key: "Extra Large", value: "extra_large" },
                ],
                // key: name displayed in dropdown
                // value: enum value used in the backend
                // moveable: all stages a particular stage can be changed into
                stages: [
                    { key: "Backlog",                   value: "backlog",                   moveable: [ 'awaiting_documentation' ],                                                             },
                    { key: "Awaiting Documentation",    value: "awaiting_documentation",    moveable: [ 'awaiting_estimate', 'backlog' ],                                                       },
                    { key: "Awaiting Estimate",         value: "awaiting_estimate",         moveable: [ 'on_deck', 'awaiting_documentation' ],                                                  },
                    { key: "On Deck",                   value: "on_deck",                   moveable: [ 'development_in_progress', 'awaiting_estimate', 'awaiting_documentation' ],             },
                    { key: "Development in Progress",   value: "development_in_progress",   moveable: [ 'dev_blocked', 'dev_paused', 'dev_qa' ],                                                },
                    { key: "Dev Blocked",               value: "dev_blocked",               moveable: [ 'dev_paused', 'development_in_progress', 'dev_qa', 'technical_rework' ],                },
                    { key: "Dev Paused",                value: "dev_paused",                moveable: [ 'dev_blocked', 'development_in_progress', 'dev_qa', 'technical_rework' ],               },
                    { key: "Dev QA",                    value: "dev_qa",                    moveable: [ 'awaiting_code_review', 'dev_blocked', 'dev_paused' ],                                  },
                    { key: "Awaiting Code Review",      value: "awaiting_code_review",      moveable: [ 'technical_rework', 'awaiting_code_merge' ],                                            },
                    { key: "Technical Rework",          value: "technical_rework",          moveable: [ 'awaiting_code_merge', 'dev_blocked', 'dev_paused', 'awaiting_code_review' ],           },
                    { key: "Awaiting Code Merge",       value: "awaiting_code_merge",       moveable: [ 'merged', 'technical_rework' ],                                                         },
                    { key: "Merged",                    value: "merged",                    moveable: [ 'awaiting_deploy' ],                                                                    },
                    { key: "Awaiting Deploy",           value: "awaiting_deploy",           moveable: [ 'in_production' ],                                                                      },
                    { key: "In Production",             value: "in_production",             moveable: [ ],                                                                                      },
                ],
                statuses: [
                    { key: "Done", value: "done" },
                    { key: "In Review", value: "in_review" },
                    { key: "In Progress", value: "in_progress" },
                    { key: "Open", value: "open" },
                ],
                types: [
                    { key: "Bug", value: "bug" },
                    { key: "Enhancement", value: "enhancement" },
                    { key: "Feature", value: "feature" },
                    { key: "Question", value: "question" },
                ],
            }
        },
        methods: {
            checkAbleToUpdate() {
                // shorthand variable
                const currentStage = this.obj.internal_development_stage;
                // if the stage isn't null and the previous stage isn't null and they aren't equal
                if (currentStage !== null && this.savedStage !== null && currentStage !== this.savedStage) {
                    // find the current stage's associated object
                    const currentStageObject = this.stages.find(obj => obj.value === currentStage);
                    // same for the previous stage
                    const savedStageObject = this.stages.find(obj => obj.value === this.savedStage);

                    // if you cannot move from the previous stage to the current stage
                    if (!savedStageObject.moveable.includes(currentStage)) {
                        // get the stages you are allowed to move to
                        const allowedObjects = this.stages.filter(obj => savedStageObject.moveable.includes(obj.value));
                        // get all the friendly names of the objects
                        const friendlyNames = allowedObjects.map(obj => obj.key);
                        // concat the names
                        const showString = friendlyNames.join(', ');

                        this.showSnackbar("error", "Unable to transition from " + savedStageObject.key + " to " + currentStageObject.key + ".\bYou are able to move the stage to any of the following: " + showString + ".");
                        return false;
                    }
                }

                return true;
            },
            createTicket() {

                let assignable_type = ""
                const assignable_id = this.obj.assignable_id

                this.assignables?.forEach(assignable => {
                    if (assignable.assignable_id == assignable_id) {
                        assignable_type = (assignable.assignable_type || '').toLowerCase()
                    }
                });

                this.obj.current_status = 'open';
                this.obj.assignable_type = assignable_type || '';
                this.obj.current_user_id = this.currentUser?.user_id || 0;

                if (!(this.currentUserIsSuperAdmin)) {
                    this.obj.account_id = this.currentUser?.account?.account_id;
                }

                this.isSaving = true;
                CreateTicket(this.obj).then((response) => {
                    if (response?.Error === null) {
                        this.showSnackbar("success", "Successfully created ticket");
                        setTimeout(() => { this.$router.push({ name: 'ticket_index' }) }, 1500);
                    } else {
                        let err = response?.Error;
                        if (!(err?.length > 0)) {
                            err = "Unable to create ticket"
                        }
                        this.showSnackbar("error", err);
                    }

                    this.isSaving = false;
                });
            },
            getAccounts() {
                GetAccounts({ sort_by_column_name: "name" }).then((response) => {
                    if (response?.Data?.accounts?.length > 0) {
                        this.accounts = response.Data.accounts;
                    }
                });
            },
            getAssignables() {
                // If super admin, get assignables for selected account
                let options = {};
                if (this.currentUserIsSuperAdmin) {
                    if (this.validTicket) {
                        // If valid ticket, set account_id to exisiting ticket's account id
                        options = { account_id: this.ticket.account_id };
                    } else {
                        // If creating new ticket, set account_id to selected account's id
                        options = { account_id: this.obj.account_id };
                    }
                }

                GetAssignables(options).then((response) => {
                    if (response?.Data?.assignables.length > 0) {
                        let assignables = response.Data.assignables

                        const assignableMap = new Map();
                        assignables.forEach(function (assignable) {
                            assignableMap.set(assignable.assignable_type, [{
                                header: assignable.assignable_type
                            }])
                        });

                        assignables.forEach(function (assignable) {
                            let item = assignableMap.get(assignable.assignable_type)
                            if (item) {
                                item.push(assignable);
                            } else {
                                item = [];
                            }

                            assignableMap.set(assignable.assignable_type, item)
                        });

                        assignables = []
                        assignableMap.forEach(function (value) {
                            assignables = assignables.concat(value)
                        });

                        this.assignables = assignables;
                    }
                });
                return;
            },
            initForm() {
                if (this.ticket) {
                    this.obj = { ...this.ticket };

                    this.savedStage = this.obj.internal_development_stage;
                }

                if (this.currentUserIsSuperAdmin && !this.validTicket) {
                    this.getAccounts();
                }
                // this.clearHours();
            },
            onSelectAccount() {
                this.getAssignables();
            },
            onUpdatedAssets(assets) {
                const media = [];
                assets.forEach(asset => {
                    media.push({"mediaable_type": "asset", "mediaable_id": asset.id});
                });

                this.obj.media = media;
            },
            sanatizeActualHours() {
                this.obj.internal_development_actual_hours = this.sanitizeDigits(this.obj.internal_development_actual_hours);
            },
            sanitizeDigits(digits) {
                digits = String(digits);
                // if digits is empty or is a negative number
                // default to 0
                if (digits.length === 0 || Number(digits) < 0) {
                    digits = '0';
                }
                // if there are 2 or more digits and 0 is the first digit
                // remove the 0
                if (digits.length > 1 && digits[0] === '0') {
                    digits = digits.substring(1);
                }
                return Number(digits);
            },
            sanatizeEstimateHours() {
                this.obj.internal_development_estimate_hours = this.sanitizeDigits(this.obj.internal_development_estimate_hours);
            },
            updateTicket() {

                if (!this.checkAbleToUpdate()) {
                    return;
                }

                const assignable_id = this.obj.assignable_id;
                let assignable_type;
                this.assignables?.forEach(assignable => {
                    if (assignable.assignable_id == assignable_id) {
                        assignable_type = (assignable.assignable_type || '').toLowerCase();
                    }
                });

                // sanatize the development priority number
                this.obj.internal_development_priority = this.sanitizeDigits(this.obj.internal_development_priority);

                // if it was unable to parse
                if (isNaN(this.obj.internal_development_priority) || this.obj.internal_development_priority === 0) {
                    // default to null
                    this.obj.internal_development_priority = null;
                }

                // ensure priority is between 1 and 10
                if (this.obj.internal_development_priority !== null && (this.obj.internal_development_priority < 1 || this.obj.internal_development_priority > 10)) {
                    this.showSnackbar("error", "Development Priority must be set to a number between 1 and 10!");

                    return;
                }

                // initialize update options
                const options = {
                    assignable_id:   parseInt(assignable_id || 0),
                    assignable_type: assignable_type || '',
                    branch_name:     this.obj.branch_name || '',
                    comments:        this.obj.comments || '',
                    commit_number:   this.obj.commit_number || '',
                    completion_code: this.obj.completion_code || '',
                    current_status:  this.obj.current_status || '',
                    description:     this.obj.description || '',
                    internal_development_actual_hours:   parseInt(this.obj.internal_development_actual_hours || 0),
                    internal_development_estimate_hours: parseInt(this.obj.internal_development_estimate_hours || 0),
                    internal_development_priority:       this.obj.internal_development_priority || 0,
                    internal_development_size:           this.obj.internal_development_size || '',
                    internal_development_stage:          this.obj.internal_development_stage || '',
                    media:           this.obj.media || [],
                    priority:        this.obj.priority || '',
                    ticket_type:     this.obj.ticket_type || '',
                    title:           this.obj.title || '',
                }

                this.isSaving = true;
                UpdateTicket(this.ticket.ticket_key, options).then((response) => {
                    if (response?.Error === null) {
                        this.showSnackbar("success", "Successfully updated ticket");

                        this.reloadTicket();
                    } else {
                        let err = response?.Error;
                        if (!(err?.length > 0)) {
                            err = "Unable to update ticket"
                        }
                        this.showSnackbar("error", err);
                    }

                    this.isSaving = false;
                });
            },
        },
        created() {
            if (this.validTicket || !this.currentUserIsSuperAdmin) {
                this.getAssignables();
            }

            this.initForm();
        },
        components: {
            Asset,
            ReopenTicketDialog,
            TiptapVuetify,
        },
        computed: {
            currentUser() {
                return VolieState.currentUser();
            },
            currentUserIsSuperAdmin() {
                return this.currentUser?.activated_role_id === 1;
            },
            disableAssignables() {
                return this.currentUserIsSuperAdmin && !(this.obj.account_id > 0);
            },
            eligibleToSave() {
                if (!(this.obj?.title?.length > 0)) {
                    return false;
                }
                if (!(this.obj?.ticket_type?.length > 0)) {
                    return false;
                }
                if (!(this.obj?.priority?.length > 0)) {
                    return false;
                }
                if (!(this.obj?.assignable_id > 0)) {
                    return false;
                }
                if (!(this.obj?.description?.length > 0)) {
                    return false;
                }

                return true;
            },
            validTicket() {
                return this.ticket?.ticket_id > 0;
            },
        },
        watch: {
            obj: function(value) {
                const newStage = value.internal_development_stage;

                if (newStage === null) {
                    this.shownStages = this.stages;

                    return;
                }

                const stageObject = this.stages.find(obj => obj.value === newStage);
                this.shownStages = [];

                for (let i = 0; i < this.stages.length; i++) {
                    if (this.stages[i].value === newStage || stageObject.moveable.includes(this.stages[i].value)) {
                        this.shownStages.push(this.stages[i]);
                    }
                }
            },
        },
        props: {
            reloadTicket: Function,
            showSnackbar: Function,
            ticket:       Object,
        }
    });
</script>

<style lang="scss" scoped>
</style>
