<template lang="pug">
    ExpandableCardListItem.mt-0.mb-4(
        v-if="validData"
        :color="itemColor"
        :content-right-top="titleCreated(data.friendly_created_at)"
        expandable
        :is-expanded="showDetails === true"
        :on-expand="() => showDetails = !showDetails"
        :title="friendlyTitle(data.event_type)"
    )
        template(v-slot:rightTop)
            strong.text-uppercase {{ titleCreated(data.friendly_created_at) }}

        template(v-slot:body)
            v-card-text.white.text--primary(v-if="showDetails === true")
                StellaAiData(
                    v-if="validData"
                    :auto-assist-data="data"
                    :valid-auto-assist-data="validData"
                )

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState  from "@/store";

    // Components
    import ExpandableCardListItem from '@/components/shared/expandable_card_list_item.vue';
    import StellaAiData from '@/components/ai_assistants/stella_ai_data.vue';

    // Helpers
    import { resourceColorClass, } from "@/helpers/css_classes";

    export default Vue.extend({
        data() {
            return {
                showDetails: false,
            }
        },
        methods: {
            friendlyTitle(title) {
                switch(title) {
                case "booking":
                    return "Appointment Booked";
                case "cancellation":
                    return "Appointment Cancelled";
                case "hangup":
                    return "Customer Hangup";
                case "warm-transfer":
                    return "Call Transferred";
                default:
                    return "Unknown Outcome";
                } 
            },
            titleCreated(created) {
                return created.split(' at ')[0]
            },
        },
        computed: {
            itemColor() {
                return resourceColorClass("Call");
            },
            validData() {
                return this.data?.auto_assistant_event_id > 0 && this.data?.event_body?.length > 0;
            },
        },
        components: {
            ExpandableCardListItem,
            StellaAiData,
        },
        props: {
            data: Object,
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        .no-top-bottom-padding {
            padding-top: 0;
            padding-bottom: 0;
        }

        .cell-value-spacing {
            padding: 0 0 5px 0;
        }

        .m-l-5 {
            margin-left: 5px;
        }
    }
</style>