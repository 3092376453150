<template lang="pug">
    draggable(v-if="draggable" :list="items" tag="tbody" @change="onDraggableChange($event)" scroll-sensitivity='500')
        slot(name="tbody-draggable")
    tbody(v-else)
        slot(name="tbody-draggable")

</template>

<script lang="ts">
    import Vue from 'vue';
    import draggable from "vuedraggable";

    export default Vue.extend({
        data() {
            return {}
        },
        methods: {},
        computed: {},
        components: {
            draggable,
        },
        props: {
            draggable: Boolean,
            items: Array,
            onDraggableChange: Function,
        },
    });
</script>

<style lang="scss" scoped>
</style>