<template lang="pug">
    div.mx-lg-auto(width="100%")
        v-container.mx-auto(v-if="validStellaAiData")

            template 
                v-divider(inset)
                v-row
                    v-col(cols='6')
                        v-list-item
                            v-list-item-icon 
                                v-icon(color='primary')
                                    | mdi-calendar
                            v-list-item-content 
                                v-list-item-title.list-item-title-overflow 
                                    | {{ autoAssistData.friendly_created_at }}
                                v-list-item-subtitle.text-wrap
                                    | Created At
                v-divider(inset)
                v-row
                    v-col(cols='6')
                        v-list-item
                            v-list-item-icon 
                                v-icon(color='primary')
                                    | mdi-account-tie-woman
                            v-list-item-content 
                                v-list-item-title.list-item-title-overflow 
                                    | Stella AI
                                v-list-item-subtitle.text-wrap
                                    | AI Assistant Provider

                    v-col(cols='6' align='left')         
                        v-list-item
                            v-list-item-content 
                                v-list-item-title.list-item-title-overflow.text-wrap
                                    | {{ eventBody["event-summary"]["call-outcome"] }} 
                                v-list-item-subtitle.text-wrap
                                    | Call Outcome

                            
                v-divider(inset)
                v-row
                    v-col(cols='6')
                        v-list-item
                            v-list-item-icon 
                                v-icon(color='primary')
                                    | mdi-account-details-outline
                            v-list-item-content 
                                v-list-item-title.list-item-title-overflow 
                                    | {{ eventBody.header["caller-first-name"] }} 
                                v-list-item-subtitle 
                                    | Caller First Name
                    v-col(cols='6')
                        v-list-item
                            v-list-item-content 
                                v-list-item-title.list-item-title-overflow 
                                    | {{ eventBody.header["caller-last-name"] }} 
                                v-list-item-subtitle 
                                    | Caller Last Name 

                v-divider(inset)
                v-row
                    v-col(cols='4')
                        v-list-item
                            v-list-item-icon 
                                v-icon(color='primary')
                                    | mdi-car
                            v-list-item-content 
                                v-list-item-title.list-item-title-overflow.text-wrap 
                                    div(v-if="eventBody['event-summary']['vehicle']['year']")
                                        | {{ eventBody['event-summary']['vehicle']['year'] }}
                                    div(v-else)
                                        | N/a
                                v-list-item-subtitle.text-wrap 
                                    | Vehicle Year
                    v-col(col='4')
                        v-list-item
                            v-list-item-content 
                                v-list-item-title.list-item-title-overflow.text-wrap
                                    div(v-if="eventBody['event-summary']['vehicle']['make']")
                                        | {{ eventBody['event-summary']['vehicle']['make'] }}
                                    div(v-else)
                                        | N/a
                                v-list-item-subtitle.text-wrap 
                                    | Vehicle Make
                    v-col(vols='4')
                        v-list-item
                            v-list-item-content 
                                v-list-item-title.list-item-title-overflow.text-wrap
                                    div(v-if="eventBody['event-summary']['vehicle']['model']")
                                        | {{ eventBody['event-summary']['vehicle']['model'] }}
                                    div(v-else)
                                        | N/a
                                v-list-item-subtitle.text-wrap 
                                    | Vehicle Model
                
                v-list-item
                    v-list-item-icon 
                    v-list-item-content 
                        v-list-item-title.list-item-title-overflow 
                            div(v-if="eventBody['event-summary']['vehicle']['mileage']")
                                | {{ eventBody['event-summary']['vehicle']['mileage'] | number }}
                            div(v-else)
                                | N/a
                        v-list-item-subtitle 
                            | Vehicle Odometer

                v-divider(inset)
                div(v-if="validStellaTranscription")
                    v-list-item
                        v-list-item-icon 
                            v-icon(color="primary")
                                | mdi-account-voice
                        v-list-item-content
                            v-list-item-title.font-weight-bold Call Transcript:
                    v-list-item(v-for="utterance, i in eventBody.transcript" :key="i")
                        v-list-item-icon 
                        v-list-item-content 
                            v-list-item-title.text-wrap.list-item-title-overflow(class="lightgrey")
                                | "{{ utterance["text"] }}"
                            v-list-item-subtitle(:class="determineSpeakerClass(utterance['line-type'])")
                                | Speaker - {{ determineSpeaker(utterance["line-type"]) }}

                div(v-if="!validStellaAiData")
                    v-alert(outlined type='info' dense border='left' style="margin-top: 16px;")
                        | There is no call transcription for this call.

        div(v-if="!validStellaAiData")
            v-alert(outlined type='info' dense border='left' style="margin-top: 16px;")
                | There is no valid data for this call.

</template>

<script lang="ts">
import Vue from 'vue';

import Chartkick from 'vue-chartkick';
import Chart from 'chart.js';

Vue.use(Chartkick.use(Chart));

// Components

export default Vue.extend({
    data() {
        return {
            eventBody: {},
        }
    },
    methods: {
        determineSpeaker(lineType) {
            if (lineType == "caller") {
                return "Caller";
            } 

            return "Stella AI";
        },
        determineSpeakerClass(lineType) {
            if (lineType == "caller") {
                return "speaker-caller";
            } 

            return "speaker-ai";
        },
    },
    computed: {
        validStellaAiData() {
            return this.eventBody;
        },
        validStellaTranscription() {
            return this.eventBody && this.eventBody.transcript?.length > 0;
        }
    },
    components: {
    },
    props: {
        autoAssistData: Object,
        validAutoAssistData: Boolean,
    },
    watch: {
    },
    created() {
        if (this.validAutoAssistData) {
            this.eventBody = JSON.parse(this.autoAssistData.event_body);
        }
    },
    destroyed() {
    },
})
</script>

<style lang="scss" scoped>
#app {
    .speaker-caller {
        color: blue;
    }
    .speaker-ai {
        color: green;
    }
}
</style>