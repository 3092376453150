import { get, put } from '.';

export function GetAutoAssistantRequests(options = {}) {
	return get<any>(`/app/api/auto_assistant_requests`, options);
}

export function UpdateAutoAssistantRequest(id: string, options = {}) {
	return put<any>(`/app/api/auto_assistant_requests/${id}`, options);
}

export function FindAutoAssistantRequest(id: string) {
	return get<any>(`/app/api/auto_assistant_requests/${id}`);
}