import { destroy, get, post, put } from '.';

export function GetExternalLinks(options = {}) {
	return get<any>("/app/api/external_links", options);
}

export function CreateExternalLink(options = {}) {
    return post<any>("/app/api/external_links", options);
}

export function UpdateExternalLink(id, options = {}) {
    return put<any>("/app/api/external_links/" + (id || 0).toString(), options);
}

export function DestroyExternalLink(id, options = {}) {
    return destroy<any>("/app/api/external_links/" + (id || 0).toString());
}