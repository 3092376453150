import { get } from '.';

export function GetCampaignInheritancesAdfEmails(parentId: string, options = {}) {
	return get<any>(`/app/api/campaigns/${parentId}/inheritances/adf_email_addresses`, options);
}

export function GetCampaignInheritancesAlertRecipients(parentId: string, options = {}) {
    return get<any>(`/app/api/campaigns/${parentId}/inheritances/alert_recipients`, options);
}

export function GetCampaignInheritancesCustomFields (id: string, options) {
    return get <any>(`/app/api/campaigns/${id}/inheritances/custom_fields`, options)
}

export function GetCampaignInheritancesPhoneNumbers (id: string, options) {
    return get<any>(`/app/api/campaigns/${id}/inheritances/incoming_phone_numbers`, options)
}

export function GetCampaignInheritancesInstantVms(parentId: string, options = {}) {
    return get<any>(`/app/api/campaigns/${parentId}/inheritances/instant_voicemails`, options);
}

export function GetCampaignInheritancesSpecialInstructions(id: string, options) {
	return get <any>(`/app/api/campaigns/${id}/inheritances/special_instructions`, options)
}


export function GetCampaignInheritancesQueryParams (id: string, options) {
	return get<any>(`/app/api/campaigns/${id}/inheritances/query_params`, options)
}
