<template lang="pug">
    .text-center.width-100-percent(v-if="itemsCount > 0")
        v-btn.width-100-percent(block link text small disabled tile :outlined="outlined")
            span(style="color: rgba(0, 0, 0, 0.6); font-size: 0.75rem; width: 100%; text-transform: none;" :class="textClass")
                | {{ itemsCountDisplay }}

</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        computed: {
            itemsCount() {
                return this.items?.length || 0;
            },
            itemsCountDisplay() {
                if (this.itemsCount === 0) {
                    return '0-0';
                }

                // Make sure total results is at least as big as items array length
                let totalResultsStr = '';
                if (this.totalResults >= this.items?.length) {
                    totalResultsStr = ' of ' + this.totalResults.toLocaleString();
                }

                return '1-'+this.itemsCount.toLocaleString()+totalResultsStr;
            },
            textClass() {
                if (this.align === "center") {
                    return "text-center";
                }
                if (this.align === "left") {
                    return "text-left";
                }
                if (this.align === "right") {
                    return "text-right";
                }

                return "text-right";
            },
        },
        props: {
            items: Array,
            align: String, // center, left, right (default)
            outlined: Boolean, // outlined is best with white header bg, not outlined with colored bg header
            totalResults: Number,
        },
    });
</script>

<style lang="scss" scoped>
</style>
