<template lang="pug">
    v-menu(
        offset-y
        class="position-relative menu"
        :close-on-content-click="false"
    )
        template(v-slot:activator="{ on, attrs }")
            v-btn(
                v-bind="attrs"
                v-on="on"
                color="white"
                class="goals-btn"
                @click="toggleGoalDisplay()"
            )
                v-progress-circular(
                    :value="goalProgressPercentage"
                    :size="42"
                    :width="4"
                    rotate="0"
                    color="primary"
                    class="position-relative"
                )
                v-icon(
                    class="position-absolute"
                    color="primary"
                    size="16"
                ) {{ getIcon }}

        v-list(class="horizontal-menu" style="display: flex; flex-direction: row; align-items: center; flex-wrap: nowrap;")
            v-list-item(style="display: inline-flex; align-items: center; justify-content: center; flex-direction: row; flex: 0 0 auto; padding: 10px; white-space: nowrap; width: auto; box-sizing: content-box;")
                v-list-item-content(style="flex: 0 0 auto;")
                    v-list-item-title(style="text-align: center; font-weight: bold; margin: 0;") {{ goal.goal_type | titleize }}

            v-divider(vertical style="height: auto; margin-right: 10px")

            div(v-for="(g, i) in goalsByGoalType" :key="g.id" style="display: flex; flex-direction: row; align-items: center; gap: 10px;")
                UserGoal(
                    :array-length="arrayLength"
                    :goal="g"
                    :index="i"
                )

                template(v-if="i < arrayLength - 1")
                    v-divider(vertical style="height: auto; margin-right: 10px;")
</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import UserGoal from "@/components/goals/user_goals/user_goal.vue";

    export default Vue.extend({
        data() {
            return {
            }
        },
        methods: {
            progressColorMenu(goal) {
                if (this.goalProgressPercentage > 0 && this.goalProgressPercentage < 25) {
                    return "error";
                } else if (this.goalProgressPercentage >= 25 && this.goalProgressPercentage < 75) {
                    return "warning lighten-2";
                } else if (this.goalProgressPercentage >= 75 && this.goalProgressPercentage < 100) {
                    return "primary";
                } else if (this.goalProgressPercentage >= 100) {
                    return "success";
                } else {
                    return "grey";
                }
            },
            toggleGoalDisplay() {
                const isOpen = this.menuVisibility[this.goal.id] || false;
                this.$set(this.menuVisibility, this.goal.id, !isOpen);
            },
        },
        computed: {
            arrayLength() {
                return this.goalsByGoalType.length;
            },
            getIcon() {
                const icons = {
                    "same_day_appointments": 'mdi-calendar-check',
                    "inbound_phone_calls": 'mdi-phone-incoming',
                    "outbound_phone_calls": 'mdi-phone-outgoing',
                    "new_appointments": 'mdi-calendar-plus',
                };
                return icons[this.goal.goal_type] || 'mdi-medal';
            },
            goalProgressPercentage() {
                if (this.goal.current_value > 0) {
                    return (this.goal.current_value / this.goal.target_value) * 100.0;
                } else {
                    return 0;
                }
            },
            goalsByGoalType() {
                const recurringOrder = {
                    daily: 1,
                    weekly: 2,
                    monthly: 3,
                    quarterly: 4,
                    yearly: 5
                };
                const filteredGoals = this.goals.filter(g => g.goal_type === this.goal.goal_type);

                return filteredGoals.sort((a, b) => {
                    return recurringOrder[a.recurring_type] - recurringOrder[b.recurring_type];
                });
            },
        },
        components: {
            UserGoal,
        },
        props: {
            goal: Object,
            goals: Array,
        },
    });
</script>

<style lang="scss" scoped>
#app {
    .goals-btn {
        min-width: 50px;
        max-width: 50px;
        min-height: 50px;
        max-height: 50px;
        width: 50px !important;
        height: 50px !important;
        border-radius: 50%;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .position-absolute {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .position-relative {
        position: relative;
    }
}
</style>
