<template lang="pug">
    div
        slot(name="top-metadata")
            TableResultsCount(
                v-if="!isLoading && validItems && !hideCount"
                :items="items"
                :outlined="!dark"
                :total-results="totalResults"
            )
        v-simple-table.table-x-scroll(:class="tableClasses" :id="'table-fixed-header'+key" fixed-header :height="tableHeight")
            template(v-slot:default)
                slot(name="thead")
                    thead(:id="'table-fixed-header-head'+key")
                        template(v-if="superHeader")
                            slot(name="thead-super")
                                tr(:class="tableHeaderRowClass")
                                    slot(name="column-super-header-first")
                                        th.text-left.no-wrap(v-if="validNumOfStickyColumns && numOfStickyColumns > 0" :class="tableHeaderThClass")
                                            slot(name="column-super-header-first-title")
                                    slot(name="column-super-header-second")
                                        th.text-left.no-wrap(v-if="validNumOfStickyColumns && numOfStickyColumns > 1" :style="{ left: firstColumnWidth + 'px'}" :class="tableHeaderThClass")
                                            slot(name="column-super-header-second-title")
                                    template(v-if="dark")
                                        th.sticky-column(v-if="validNumOfStickyColumns" :style="{ left: stickyColumnLeft + 'px'}")
                                    template(v-else)
                                        th.sticky-column-white-background(v-if="validNumOfStickyColumns" :style="{ left: stickyColumnLeft + 'px'}")
                                    slot(name="th-super-default")
                        slot(name="thead-tr")
                            tr(:class="tableHeaderRowClass")
                                slot(name="column-header-first")
                                    th.text-left.no-wrap(v-if="validNumOfStickyColumns && numOfStickyColumns > 0" :id="'column-header-first'+key" :class="tableHeaderThClass")
                                        slot(name="column-header-first-title")
                                            | Created At
                                            v-icon(small color="white") mdi-chevron-down
                                slot(name="column-header-second")
                                    th.text-left.no-wrap(v-if="validNumOfStickyColumns && numOfStickyColumns > 1" :style="{ left: firstColumnWidth + 'px'}" :id="'column-header-second'+key" :class="tableHeaderThClass")
                                        slot(name="column-header-second-title")
                                            | Organization
                                template(v-if="dark")
                                    th.sticky-column(v-if="validNumOfStickyColumns" :style="{ left: stickyColumnLeft + 'px'}" :id="'column-header-sticky'+key")
                                template(v-else)
                                    th.sticky-column-white-background(v-if="validNumOfStickyColumns" :style="{ left: stickyColumnLeft + 'px'}" :id="'column-header-sticky'+key")
                                slot(name="th-default")
                                    //- EXAMPLE BELOW
                                    //- th.text-center.no-wrap Column Name 1

                slot(name="tbody" :tableKey="key")
                    TBodyDraggable(:draggable="draggable" :items="items" :onDraggableChange="onDraggableChange" :id="'table-fixed-header-body'+key")
                        template(v-slot:tbody-draggable)
                            slot(name="tbody-tr")
                                tr(v-for='(item, idx) in items' v-if='!isLoading && validItems' :key='idx' :class="{'highlight': item.toBeHighlighted}")
                                    slot(name="column-header-first-content" :item="item")
                                        td.no-wrap.xs-width(v-if="validNumOfStickyColumns && numOfStickyColumns > 0")
                                            slot(name="column-header-first-content-value" :item="item")
                                                template(v-if='item["friendly_created_at"]') {{ item["friendly_created_at"] }}
                                                template(v-else) ---
                                    slot(name="column-header-second-content" :item="item")
                                        td.no-wrap.sm-width(v-if="validNumOfStickyColumns && numOfStickyColumns > 1" :style="{ left: firstColumnWidth + 'px'}")
                                            slot(name="column-header-second-content-value" :item="item")
                                                StrLimitTooltipText(:text='item["organization_name"]' :char-limit="30")
                                    td.sticky-column(v-if="validNumOfStickyColumns" :style="{left: stickyColumnLeft + 'px'}")
                                    slot(name="tr-default" :item="item" :idx="idx")
                                        //- Example Below
                                        //- td.no-wrap.xs-width
                                        //-     template(v-if="slotProps.item.field_1")
                                        //-         | {{ slotProps.item.field_1 }}
                                        //-     template(v-else) ---

                                tr(v-if="isLoading")
                                    td.text-center(colspan="100%")
                                        v-progress-circular(
                                            :size='100'
                                            :width='10'
                                            color='#0f5b9a'
                                            indeterminate
                                            style="margin-top: 75px; margin-bottom: 75px;"
                                        )

                            slot(name="total-row-tr")
                                tr#total-row.total-row-footer(v-if='!isLoading && validItems && totalRow' style="position: sticky; inset-block-end: 0; z-index: 4")
                                    slot(name="total-row-first-column-name")
                                        td.no-wrap.xs-width
                                            | Total
                                    td.no-wrap.xs-width(v-if="validNumOfStickyColumns && numOfStickyColumns > 1" :style="{ left: firstColumnWidth + 'px'}")
                                    td.sticky-column(v-if="validNumOfStickyColumns" :style="{left: stickyColumnLeft + 'px'}")
                                    slot(name="total-row-tr-default")
                                        //- Example Below
                                        //- td.no-wrap.xs-width
                                        //-     template(v-if="slotProps.item.field_1")
                                        //-         | {{ slotProps.item.field_1 }}
                                        //-     template(v-else) ---


        slot(name="show-more-row")
            ShowMoreRow(
                v-if="!isLoading"
                :handle-show-more="handleShowMore"
                :id-key="key"
                :loading="isShowMoreLoading"
                :show-more="showMore"
            )

        slot(name="table-alert")

        template(v-if="footer")
            div(:id="'table-footer'+key")
                slot(name='table-footer')

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import ShowMoreRow from '@/components/shared/show_more_row_new.vue';
    import StrLimitTooltipText from "@/components/shared/str_limit_tooltip_text.vue";
    import TableResultsCount from "@/components/shared/table_results_count.vue";
    import TBodyDraggable from "@/components/shared/tbody_draggable.vue";

    // Imports
    import { v4 as uuidv4 } from 'uuid';

    export default Vue.extend({
        data() {
            return {
                key: null,
                firstColumnWidth: 100,
                secondColumnWidth: 100,
                stickyColumnWidth: 2,
                tableHeight: 300,
            }
        },
        methods: {
            handleColumnStickyPosition() {
                this.firstColumnWidth = (document?.getElementById('column-header-first'+this.key)?.getBoundingClientRect()?.width || 0);
                this.secondColumnWidth = (document?.getElementById('column-header-second'+this.key)?.getBoundingClientRect()?.width || 0);
                this.stickyColumnWidth = (document?.getElementById('column-header-sticky'+this.key)?.getBoundingClientRect()?.width || 0);
            },
            handleSecondColumnStickyPosition() {
                this.firstColumnWidth = (document?.getElementById('column-header-first'+this.key)?.getBoundingClientRect()?.width || 0);
            },
            handleTableHeight() {
                const tableTop = (document?.getElementById('table-fixed-header'+this.key)?.getBoundingClientRect()?.top || 0);
                let showMoreRowHeight = 0;
                if (this.showMore) {
                    showMoreRowHeight = (document?.getElementById('show-more-row'+this.key)?.getBoundingClientRect()?.height || 44);
                }

                let tableFooterRowHeight = 0;
                if (this.footer) {
                    tableFooterRowHeight = (document?.getElementById('table-footer'+this.key)?.getBoundingClientRect()?.height || 0);
                }

                if (tableTop > 0) {

                    let maxTableHeight = 0;
                    if (this.height > 0) { // If table height is set from prop, i.e. not dynamic to fill vertical space
                        maxTableHeight = this.height;
                    } else {
                        maxTableHeight = window.innerHeight - tableTop - showMoreRowHeight - 20; // 20 coming from padding
                    }

                    const tableHead = (document?.getElementById('table-fixed-header-head'+this.key)?.getBoundingClientRect()?.height || 0);
                    const tableBody = (document?.getElementById('table-fixed-header-body'+this.key)?.getBoundingClientRect()?.height || 0);
                    const tableHeight = tableHead+tableBody+tableFooterRowHeight

                    if (tableHeight > 0 && tableHeight < maxTableHeight) {
                        this.tableHeight = tableHeight;
                    } else {
                        this.tableHeight = maxTableHeight;
                    }

                    if (this.tableHeight < 0) {
                        this.tableHeight = tableHeight;
                    }
                }
            },
        },
        computed: {
            itemsCount() {
                return this.items?.length || 0;
            },
            itemsCountDisplay() {
                if (this.itemsCount === 0) {
                    return 'No results';
                }

                return '1-'+this.itemsCount.toString();
            },
            stickyColumnLeft() {
                if (this.numOfStickyColumns === 1) {
                    return this.firstColumnWidth;
                }

                if (this.numOfStickyColumns === 2) {
                    return this.firstColumnWidth + this.secondColumnWidth;
                }

                return 0;
            },
            tableClasses() {
                if (this.numOfStickyColumns === 1) {
                    // first column + line/shadow column .sticky-column
                    return "sticky-col-inherit-1 sticky-col-inherit-2"
                }

                if (this.numOfStickyColumns === 2) {
                    // first column + second column + line/shadow column .sticky-column
                    return "sticky-col-inherit-1 sticky-col-inherit-2 sticky-col-inherit-3"
                }

                return "";
            },
            tableHeaderRowClass() {
                if (this.dark) {
                    return "volie-background-color-blue";
                }

                return "bg-white";
            },
            tableHeaderThClass() {
                if (this.dark) {
                    return "white--text";
                }

                return "";
            },
            validNumOfStickyColumns() {
                return this.numOfStickyColumns === 1 || this.numOfStickyColumns === 2;
            },
            validItems() {
                return this.items?.length > 0;
            },
        },
        components: {
            ShowMoreRow,
            StrLimitTooltipText,
            TableResultsCount,
            TBodyDraggable,
        },
        props: {
            dark: Boolean, // dark = Blue table header row background, White table header text, light/default = White table header row background, Dark grey header text
            draggable: Boolean,
            footer: Boolean,
            handleShowMore: Function,
            height: Number, // optional table height, if you don't want it to be dynamic
            hideCount: Boolean,
            isLoading: Boolean,
            isShowMoreLoading: Boolean,
            items: Array,
            numOfStickyColumns: Number, // 2 max, for now
            onDraggableChange: Function,
            showMore: Boolean,
            showSnackbar: Function,
            superHeader: Boolean,
            totalResults: Number,
            totalRow: Boolean,
        },
        watch: {
            items: {
                handler() {
                    Vue.nextTick(() => {
                        if (this.validNumOfStickyColumns) {
                            this.handleColumnStickyPosition();
                        }

                        this.handleTableHeight();
                });
                },
                deep: true,
            },
            isLoading: {
                handler() {
                    Vue.nextTick(() => {
                        if (this.validNumOfStickyColumns) {
                            this.handleColumnStickyPosition();
                        }

                        this.handleTableHeight();
                    });
                },
            },
            showMore: {
                handler() {
                    Vue.nextTick(() => {
                        this.handleTableHeight();
                    });
                },
            },
            firstColumnWidth: {
                handler() {
                    if (this.validNumOfStickyColumns && this.numOfStickyColumns > 0) {
                        Vue.nextTick(() => { this.handleSecondColumnStickyPosition(); });
                    }
                },
            },
            secondColumnWidth: {
                handler() {
                    if (this.validNumOfStickyColumns && this.numOfStickyColumns > 1) {
                        Vue.nextTick(() => { this.handleColumnStickyPosition(); });
                    }
                },
            },
        },
        created() {
            this.key = '-'+uuidv4();
			window.addEventListener('resize', () => {
                if (this.validNumOfStickyColumns) {
                    this.handleColumnStickyPosition();
                }

                this.handleTableHeight();
            });
        },
        destroyed() {
            window.removeEventListener('resize', () => {
                if (this.validNumOfStickyColumns) {
                    this.handleColumnStickyPosition();
                }

                this.handleTableHeight();
            });
        },
        mounted() {
            setTimeout(() => {
                if (this.validNumOfStickyColumns) {
                    this.handleColumnStickyPosition();
                }

                this.handleTableHeight();
            }, 500);
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        tbody {
            tr.total-row-footer{
                background-color: #7A7A7A !important;
                color: #FFFFFF !important;
                font-weight: 500 !important;
            }

            tr#total-row.total-row-footer:hover {
                background-color: #616161 !important;
            }
            tr.highlight {
                background-color: #ffebe9 !important;
            }
        }
    }
</style>
